import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PersonSchema from "../seo/PersonSchema";
import '../css/ArticleDetail.css';
import '../css/darkTheme.css';
import BlogArticleSeo from "../seo/BlogArticleSeo";
import DarkBackground from './DarkBackground';

const BackToList = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className="back-to-list animated"
      style={{
        position: 'fixed',
        left: '50%',
        bottom: '1rem',
        transform: 'translateX(-50%)',
        zIndex: 99,
        padding: '0.5rem 1.5rem',
        backgroundColor: '#00b0ff',
        border: 'none',
        borderRadius: '30px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        fontSize: '0.9rem',
        fontWeight: '500'
      }}
    >
      <i className="bi bi-arrow-left"></i>
      Back to Articles
    </button>
  );
};

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className={`copy-button ${copied ? 'copied' : ''}`}
      aria-label="Copy code"
    >
      {copied ? (
        <i className="bi bi-check2"></i>
      ) : (
        <i className="bi bi-clipboard"></i>
      )}
    </button>
  );
};

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(
          `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@zeglami`
        );

        if (response.data.status === 'ok') {
          const foundArticle = response.data.items.find(item => 
            item.guid.split('/').pop() === id
          );

          if (foundArticle) {
            setArticle(foundArticle);
          } else {
            setError('Article not found');
          }
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Failed to fetch article');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchArticle();
    }
  }, [id]);

  useEffect(() => {
    if (article) {
      // Add copy buttons to code blocks after content is loaded
      const codeBlocks = document.querySelectorAll('pre');
      codeBlocks.forEach(block => {
        const code = block.textContent;
        const wrapper = document.createElement('div');
        wrapper.className = 'code-block-wrapper';
        block.parentNode.insertBefore(wrapper, block);
        wrapper.appendChild(block);
        
        // Create and add copy button
        const copyButton = document.createElement('div');
        copyButton.className = 'copy-button-wrapper';
        const button = document.createElement('button');
        button.className = 'copy-button';
        button.innerHTML = '<i class="bi bi-clipboard"></i> Copy';
        button.onclick = async () => {
          try {
            await navigator.clipboard.writeText(code);
            button.innerHTML = '<i class="bi bi-check2"></i> Copied!';
            setTimeout(() => {
              button.innerHTML = '<i class="bi bi-clipboard"></i> Copy';
            }, 2000);
          } catch (err) {
            console.error('Failed to copy:', err);
          }
        };
        copyButton.appendChild(button);
        wrapper.appendChild(copyButton);
      });
    }
  }, [article]);

  const handleBack = () => {
    navigate('/blog');
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <DarkBackground>
        <section className="article-section">
          <div className="container">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </section>
      </DarkBackground>
    );
  }

  if (error) {
    return (
      <DarkBackground>
        <section className="article-section">
          <div className="container">
            <div className="alert alert-danger">
              {error}
              <button 
                className="btn btn-link text-white"
                onClick={() => navigate('/blog')}
              >
                Return to Blog
              </button>
            </div>
          </div>
        </section>
      </DarkBackground>
    );
  }

  if (!article) {
    return (
      <DarkBackground>
        <section className="article-section">
          <div className="container">
            <div className="alert alert-warning">
              Article not found
              <button 
                className="btn btn-link text-white"
                onClick={() => navigate('/blog')}
              >
                Return to Blog
              </button>
            </div>
          </div>
        </section>
      </DarkBackground>
    );
  }

  return (
    <DarkBackground>
      <section className="article-section">
        {article && <BlogArticleSeo article={article} />}
        <PersonSchema />
        <div className="container" style={{ paddingTop: '6rem' }}>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h1 className="article-title">
                {article.title}
              </h1>
              <div className="article-meta">
                <span className="article-date">
                  <i className="bi bi-calendar me-2"></i>
                  {new Date(article.pubDate).toLocaleDateString()}
                </span>
                <span className="mx-2">•</span>
                <span className="article-author">
                  <i className="bi bi-person me-2"></i>
                  {article.author}
                </span>
              </div>
              <div className="article-content">
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
                <a 
                  href={article.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-outline-light read-more-btn"
                >
                  Read on Medium
                </a>
              </div>
            </div>
          </div>
        </div>
        <BackToList onClick={handleBack} />
        
      </section>
    </DarkBackground>
  );
};

export default ArticleDetail;