import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Policy.css';

const PrivacyPolicy = () => {
  const currentDate = new Date().toLocaleDateString();
  const navigate = useNavigate();

  const handleContactClick = (e) => {
    e.preventDefault();
    navigate('/contact');
  };
  
  return (
    <div className="policy-container">
      <div className="policy-content">
        <br></br>
        <br></br>
        <h1>Privacy Policy</h1>
        <p className="last-updated">Last updated: {currentDate}</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            This Privacy Policy explains how we handle any information you provide through the contact form on zegit.me. We are committed to protecting your privacy and handling your data with transparency and care.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>
            We only collect information that you voluntarily provide through our contact form, which may include:
          </p>
          <ul>
            <li>Your name</li>
            <li>Your email address</li>
            <li>Your message content</li>
          </ul>
          <p>
            We do not collect any other personal data, tracking information, or usage statistics from visitors to our website.
          </p>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>
            The information you provide through the contact form is used solely for:
          </p>
          <ul>
            <li>Responding to your inquiries</li>
            <li>Communicating with you about your message</li>
          </ul>
          <p>
            We do not use your information for marketing purposes, and we do not share your data with any third parties.
          </p>
        </section>

        <section>
          <h2>4. Data Protection</h2>
          <p>
            We take appropriate measures to protect the information you send through our contact form. Your messages are treated as confidential, and we retain this information only as long as necessary to address your inquiry.
          </p>
        </section>

        <section>
          <h2>5. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or how we handle your data, please reach out through the <span 
              onClick={handleContactClick} 
              style={{ color: '#4a9eff', cursor: 'pointer', textDecoration: 'underline' }}
            >
              contact form
            </span> on this website.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 