import React from "react";
import { Helmet } from "react-helmet";

const FreelanceServicesSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Freelance Temenos T24 Expert | France & Luxembourg | Abdelhamid ZEGLAMI</title>
        <meta
          name="description"
          content="Senior Software Engineer offering freelance services in France and Luxembourg. Specialized in Temenos T24/Transact, Core Banking Solutions, and Java Spring Boot development. Available for on-site projects in Paris, Luxembourg, and remote worldwide."
        />
        <link rel="canonical" href="https://zegit.me/freelance-services" />
        
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Service",
              "serviceType": "Freelance Software Engineering",
              "provider": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer"
              },
              "areaServed": [
                {
                  "@type": "Country",
                  "name": "France"
                },
                {
                  "@type": "Country",
                  "name": "Luxembourg"
                }
              ],
              "availableLocation": [
                {
                  "@type": "Place",
                  "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "France",
                    "addressRegion": "Île-de-France"
                  }
                },
                {
                  "@type": "Place",
                  "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "Luxembourg"
                  }
                }
              ],
              "offers": {
                "@type": "Offer",
                "itemOffered": [
                  {
                    "@type": "Service",
                    "name": "Temenos T24 Implementation",
                    "description": "Expert implementation and customization services"
                  },
                  {
                    "@type": "Service",
                    "name": "Banking Software Development",
                    "description": "Full stack development for banking solutions"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default FreelanceServicesSeo; 