import React from "react";



import sk1 from "../img/sk/sk1.png"
import sk1p from "../img/sk/sk1p.png"
import sk2 from "../img/sk/sk2.png"
import sk3 from "../img/sk/sk3.png"
import sk4 from "../img/sk/sk4.png"
import sk5 from "../img/sk/sk5.png"
import sk6 from "../img/sk/sk6.png"

import ecom1 from "../img/ecom/ecom1.png"
import ecom2 from "../img/ecom/ecom2.png"
import ecom3 from "../img/ecom/ecom3.png"
import ecom4 from "../img/ecom/ecom4.png"
import ecom5 from "../img/ecom/ecom5.png"
import ecom6 from "../img/ecom/ecom6.png"
import ecom7 from "../img/ecom/ecom7.png"
import ecom8 from "../img/ecom/ecom8.png"
import ecom9 from "../img/ecom/ecom9.png"
import ecom10 from "../img/ecom/ecom10.png"
import ecom11 from "../img/ecom/ecom11.png"
import ecom12 from "../img/ecom/ecom12.png"
import ecom13 from "../img/ecom/ecom13.png"
import ecom14 from "../img/ecom/ecom14.png"
import ecom15 from "../img/ecom/ecom15.png"

import learn1p from "../img/learn/learn1p.png" 
import learn1 from "../img/learn/learn1.png"
import learn2 from "../img/learn/learn2.png"
import learn3 from "../img/learn/learn3.png"
import learn4 from "../img/learn/learn4.png"
import learn5 from "../img/learn/learn5.png"
import learn6 from "../img/learn/learn6.png"
import learn7 from "../img/learn/learn7.png"
import learn8 from "../img/learn/learn8.png"
import learn9 from "../img/learn/learn9.png"
import learn10 from "../img/learn/learn10.png"

import mo1p from "../img/mobile/mo1p.png"
import mo1 from "../img/mobile/mo1.png"
import mo2 from "../img/mobile/mo2.png"

import digit1 from "../img/digit/digit1.png"
import digit1p from "../img/digit/digit1p.png"
import digit2 from "../img/digit/digit2.png"
import digit3 from "../img/digit/digit3.png"
import digit4 from "../img/digit/digit4.png"

import ia1 from "../img/ia/ia1.webp"
import ia1p from "../img/ia/ia1p.webp"

import listBank from "../img/banking/listBank.webp"
import homeBank from "../img/banking/homeBank.webp"

import homeAerospace from "../img/aerospace/homeAerospace.webp"
import listAerospace from "../img/aerospace/listAerospace.webp"


import PersonSchema from "../seo/PersonSchema";
import DarkBackground from './DarkBackground';
import '../css/darkTheme.css';
import '../css/Portfolio.css';



class Portfolio extends React.Component {
  render() {
    return (
      <DarkBackground>
        <section id="work" className="portfolio-section route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h5 style={{color:"orange"}} className="title-left">Projects</h5>
                <div className="box-shadow-full">
                  <div className="row">
                    {/* Banking Projects */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={listBank} data-lightbox="gallery-banking">
                          <div className="work-img">
                            <img src={homeBank} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">Banking</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                    ReactJS, Java, Temenos T24
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    {/* Aerospace Projects */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={listAerospace} data-lightbox="gallery-aerospace">
                          <div className="work-img">
                            <img src={homeAerospace} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">Aerospace</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">3DEXPERIENCE, Java, JPO, FMEA, MQL</span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    {/* AI Projects */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={ia1} data-lightbox="gallery-mf">
                          <div className="work-img">
                            <img src={ia1p} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">AI</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                    Python,scikit-learn
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={ia1}
                          data-lightbox="gallery-mf"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      
                      </div>
                    </div>

                    {/* E-commerce website (ReactJS) */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={ecom1} data-lightbox="gallery-aguadeluz">
                          <div className="work-img">
                            <img src={ecom1} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">e-commerce </h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                    ReactJS,NodeJS,mongodb,CSS,HTML
                                  </span>{" "}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={ecom11}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom12}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom14}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom13}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom10}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom15}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom2}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom3}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom4}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom5}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom6}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom7}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom8}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={ecom9}
                          data-lightbox="gallery-aguadeluz"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      </div>
                    </div>

                    {/* E-learning platform */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={learn1} data-lightbox="gallery-todo">
                          <div className="work-img">
                            <img src={learn1p} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">E-learning platform</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                    HTML5 CSS3 Bootstrap Webpack ReactJS
                                  </span>
                                  {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={learn2}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn3}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn4}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn5}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn6}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn7}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn8}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn9}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={learn10}
                          data-lightbox="gallery-todo"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      </div>
                    </div>

                    {/* Mobile application */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={mo1p} data-lightbox="gallery-medlingos">
                          <div className="work-img">
                            <img src={mo1} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">Mobile app</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                  Expo XDE, React Native, Symfony ,SQL Server
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={mo2}
                          data-lightbox="gallery-medlingos"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      </div>
                    </div>

                    {/* Digitization project */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={digit1p} data-lightbox="gallery-smth">
                          <div className="work-img">
                            <img src={digit1} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">Corporate Customer Onboarding</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">ReactJS, Redux/Redux-form, Spring boot, JUnit, Git, SQL Server</span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={digit2}
                          data-lightbox="gallery-smth"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={digit3}
                          data-lightbox="gallery-smth"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={digit4}
                          data-lightbox="gallery-smth"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      </div>
                    </div>

                    {/* E-commerce website (WordPress) */}
                    <div className="col-md-4">
                      <div className="work-box">
                        <a href={sk1p} data-lightbox="gallery-vmarine">
                          <div className="work-img">
                            <img src={sk1} alt="" className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">e-commerce</h2>
                                <div className="w-more">
                                  <span className="w-ctegory">
                                    wordpress,aws,woocommerce,php,html,css
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <span className="ion-ios-plus-outline"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          href={sk2}
                          data-lightbox="gallery-vmarine"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={sk6}
                          data-lightbox="gallery-vmarine"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={sk3}
                          data-lightbox="gallery-vmarine"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={sk4}
                          data-lightbox="gallery-vmarine"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                        <a
                          href={sk5}
                          data-lightbox="gallery-vmarine"
                          style={{ display: "none" }}
                        >
                          jsx-a11y/anchor-has-content warning
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PersonSchema/>
        </section>
      </DarkBackground>
    );
  }
}

export default Portfolio;
