import React from "react";
import { Helmet } from "react-helmet";

const PortfolioSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Project Portfolio | Banking Software Solutions - Abdelhamid ZEGLAMI</title>
        <meta
          name="description"
          content="Portfolio of successful Temenos T24 implementations, core banking projects, and full-stack development solutions. View case studies from France and Luxembourg."
        />
        <link rel="canonical" href="https://zegit.me/portfolio" />
        
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "CollectionPage",
              "name": "Software Development Portfolio",
              "description": "Banking software and Temenos T24 implementation projects",
              "provider": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer"
              }
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default PortfolioSeo; 