import React from "react";
import { Helmet } from "react-helmet";
import HomeSeo from "./HomeSeo";
import AboutSeo from "./AboutSeo";
import SkillsSeo from "./SkillsSeo";
import ContactMeSeo from "./ContactMeSeo";
import ExperienceSeo from "./ExperienceSeo";
import PortfolioSeo from "./PortfolioSeo";
import BlogSeo from "./BlogSeo";
import BankingSolutionsSeo from "./BankingSolutionsSeo";
import FreelanceServicesSeo from "./FreelanceServicesSeo";

const SeoWrapper = ({ path }) => {
  const getSeoComponent = () => {
    switch (path) {
      case "/":
        return <HomeSeo />;
      case "/about":
        return <AboutSeo />;
      case "/skills":
        return <SkillsSeo />;
      case "/experience":
        return <ExperienceSeo />;
      case "/portfolio":
        return <PortfolioSeo />;
      case "/contact":
        return <ContactMeSeo />;
      case "/blog":
        return <BlogSeo />;
      case "/banking-solutions":
        return <BankingSolutionsSeo />;
      case "/freelance-services":
        return <FreelanceServicesSeo />;
      default:
        return <HomeSeo />;
    }
  };

  return getSeoComponent();
};

export default SeoWrapper; 