import React from "react";
import { Helmet } from "react-helmet";

const SkillsSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Freelance Temenos T24 & Banking Software Expert | France & Luxembourg</title>
        <meta
          name="description"
          content="Available for freelance projects in France, Luxembourg, and remote worldwide. Expert consultant in Temenos T24/Transact, Java Spring Boot, and banking solutions. Specialized in core banking implementations and digital transformation."
        />
        <meta
          name="keywords"
          content="Freelance Temenos Consultant France, T24 Expert Luxembourg, Remote Banking Software Developer, Core Banking Freelancer, Digital Banking Consultant"
        />
        <link rel="canonical" href="https://zegit.me/skills" />
        <meta name="geo.region" content="FR, LU" />
        <meta name="geo.position" content="48.8566;2.3522" />
        <meta name="ICBM" content="48.8566, 2.3522" />
      </Helmet>
    </div>
  );
};

export default SkillsSeo; 