import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Policy.css';

const Terms = () => {
  const navigate = useNavigate();

  const handleContactClick = (e) => {
    e.preventDefault();
    navigate('/contact');
  };

  return (
    <div className="policy-container">
      <div className="policy-content">
      <br></br>
      <br></br>
        <h1>Terms of Use</h1>
        <p className="last-updated">Last updated: {new Date().toLocaleDateString()}</p>
        
        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using zegit.me, you accept and agree to be bound by these Terms of Use. This website serves as my personal portfolio, featuring my projects, blog posts, and professional experiences.
          </p>
        </section>
        
        <section>
          <h2>2. Content Usage</h2>
          <p>
            All content on this website including blog posts, project descriptions, images, and other materials are my intellectual property unless stated otherwise. You may:
          </p>
          <ul>
            <li>Read and share blog posts with proper attribution</li>
            <li>View project demonstrations and descriptions</li>
            <li>Use code snippets shared in blog posts for personal learning</li>
          </ul>
          <p>
            However, you may not:
          </p>
          <ul>
            <li>Republish content without explicit permission</li>
            <li>Use content for commercial purposes without authorization</li>
            <li>Modify or claim ownership of shared projects and experiences</li>
          </ul>
        </section>

        <section>
          <h2>3. Blog Comments and Interactions</h2>
          <p>
            When interacting through the contact form or commenting on blog posts (if enabled), you agree to:
          </p>
          <ul>
            <li>Provide respectful and constructive feedback</li>
            <li>Not post spam or malicious content</li>
            <li>Not impersonate others or provide false information</li>
          </ul>
        </section>

        <section>
          <h2>4. Disclaimer</h2>
          <p>
            The content on this portfolio website is provided "as is". While I strive to keep information accurate and up-to-date:
          </p>
          <ul>
            <li>Technical information and tutorials are provided for educational purposes</li>
            <li>Code snippets and solutions should be used at your own risk</li>
            <li>Views expressed in blog posts are personal opinions</li>
          </ul>
        </section>

        <section>
          <h2>5. External Links</h2>
          <p>
            This portfolio may contain links to external projects, repositories, or resources. I am not responsible for the content or privacy practices of external sites.
          </p>
        </section>

        <section>
          <h2>6. Modifications</h2>
          <p>
            I reserve the right to modify or update these terms at any time. Continued use of the website after changes constitutes acceptance of the updated terms.
          </p>
        </section>

        <section>
          <h2>7. Contact</h2>
          <p>
            If you have any questions about these Terms of Use or would like to request permission to use content, please reach out through the <span 
              onClick={handleContactClick} 
              style={{ color: '#4a9eff', cursor: 'pointer', textDecoration: 'underline' }}
            >
              contact form
            </span> on this website.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms; 