import React from 'react';
import PersonSchema from '../seo/PersonSchema';

const BankingSolutions = () => {
  return (
    <section className="sect-pt4">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <h2>Banking Solutions</h2>
              
              <div className="banking-solutions">
                <h3>Temenos T24/Transact Expertise</h3>
                <ul>
                  <li>Core Banking Implementation</li>
                  <li>T24 Customization & Development</li>
                  <li>System Integration</li>
                  <li>Performance Optimization</li>
                </ul>

                <h3>Core Banking Services</h3>
                <ul>
                  <li>Banking Software Architecture</li>
                  <li>Digital Banking Solutions</li>
                  <li>API Integration</li>
                  <li>Banking Process Automation</li>
                </ul>

                <h3>Technical Expertise</h3>
                <ul>
                  <li>Java Spring Boot Development</li>
                  <li>Microservices Architecture</li>
                  <li>Banking APIs Development</li>
                  <li>Security Implementation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PersonSchema />
    </section>
  );
};

export default BankingSolutions; 