import React from 'react';
import PersonSchema from '../seo/PersonSchema';

const FreelanceServices = () => {
  return (
    <section className="sect-pt4">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <h2>Freelance Software Engineering Services</h2>
              
              <div className="freelance-services">
                <h3>Services Available In:</h3>
                <ul>
                  <li>France - On-site Services</li>
                  <li>Luxembourg - On-site Services</li>
                  <li>Worldwide - Remote Services</li>
                </ul>

                <h3>Specialized Services</h3>
                <ul>
                  <li>Temenos T24/Transact Implementation</li>
                  <li>Core Banking Solutions</li>
                  <li>Java Spring Boot Development</li>
                  <li>Full Stack Development</li>
                  <li>Banking Software Architecture</li>
                </ul>

                <h3>Engagement Models</h3>
                <ul>
                  <li>Project-based Consulting</li>
                  <li>Long-term Contracts</li>
                  <li>Technical Advisory</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PersonSchema />
    </section>
  );
};

export default FreelanceServices; 