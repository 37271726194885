import React from "react";
import { Helmet } from "react-helmet";

const HomeSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Abdelhamid ZEGLAMI | Banking Software Expert - Temenos T24 Specialist</title>
        <meta
          name="description"
          content="Senior Software Engineer specializing in Temenos T24, Core Banking Solutions, and Full Stack Development. Available for projects in France, Luxembourg, and remote worldwide."
        />
        <link rel="canonical" href="https://zegit.me" />
        
        {/* Social Media Tags */}
        <meta property="og:title" content="Abdelhamid ZEGLAMI | Banking Software Expert" />
        <meta property="og:description" content="Temenos T24 Specialist & Full Stack Developer available for projects in France, Luxembourg, and worldwide" />
        <meta property="og:url" content="https://zegit.me" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Abdelhamid ZEGLAMI | Banking Software Expert" />
        <meta name="twitter:description" content="Temenos T24 Specialist & Full Stack Developer" />
        
        {/* Structured Breadcrumbs */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Banking Solutions",
                  "item": "https://zegit.me/banking-solutions"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Freelance Services",
                  "item": "https://zegit.me/freelance"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Portfolio",
                  "item": "https://zegit.me/portfolio"
                }
              ]
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default HomeSeo; 