import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../css/navbar.css';
import logo1 from "../img/male1.png";
import logo2 from "../img/male.png";

const Navbar = () => {
  const [logo, setLogo] = useState(logo1);
  const [isReduced, setIsReduced] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setIsReduced(true);
        setLogo(logo2);
      } else {
        setIsReduced(false);
        setLogo(logo1);
      }
    };

    if (location.pathname === '/') {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav 
      className={`navbar navbar-b navbar-expand-md fixed-top ${
        isReduced || location.pathname !== '/' ? "navbar-reduce" : "navbar-trans"
      } ${isOpen ? "show-mobile-menu" : ""} mobile-navbar`} 
      id="mainNav"
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        background: window.innerWidth <= 768 ? 'linear-gradient(135deg, rgba(0,0,0,0.97) 0%, rgba(0,32,63,0.95) 30%, rgba(0,64,128,0.92) 70%, rgba(0,0,0,0.97) 100%)' : 'transparent'
      }}
    >
      <div className="container" style={{ backgroundColor: isOpen ? '#ffffff' : 'transparent' }}>
        <Link 
          className="navbar-brand" 
          to="/" 
          onClick={() => handleNavigation('/')}
          style={{ padding: window.innerWidth <= 768 ? '0 5px' : '' }}
        >
          <img 
            src={logo} 
            alt="logo" 
            style={{ 
              maxWidth: "150px",
              maxHeight: "55px",
              transition: "all 0.3s ease-in-out"
            }} 
          />
        </Link>
        <button
          className={`navbar-toggler ${!isOpen ? 'collapsed' : ''}`}
          type="button"
          onClick={toggleNavbar}
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div 
          className={`navbar-collapse collapse ${isOpen ? 'show' : ''}`} 
          id="navbarDefault"
          style={{ backgroundColor: isOpen ? '#ffffff' : 'transparent' }}
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/') ? 'active' : ''}`} to="/" onClick={() => handleNavigation('/')}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/about') ? 'active' : ''}`} to="/about" onClick={() => handleNavigation('/about')}>
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/experience') ? 'active' : ''}`} to="/experience" onClick={() => handleNavigation('/experience')}>
                Experience
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/skills') ? 'active' : ''}`} to="/skills" onClick={() => handleNavigation('/skills')}>
                Skills
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/portfolio') ? 'active' : ''}`} to="/portfolio" onClick={() => handleNavigation('/portfolio')}>
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/blog') ? 'active' : ''}`} to="/blog" onClick={() => handleNavigation('/blog')}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/contact') ? 'active' : ''}`} to="/contact" onClick={() => handleNavigation('/contact')}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;