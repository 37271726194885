// About.js
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/AboutMe.css';
import './../css/darkTheme.css';
import PersonSchema from '../seo/PersonSchema';
import AboutSeo from '../seo/AboutSeo';
import BackHome from './back-home';
import DarkBackground from './DarkBackground';

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      fadeIn: false,
      education: [
        {
          id: "first-p-about",
          school: "National School For Computer Science",
          degree: "Engineering school",
          year: "2015 – 2019",
          details: "Branch: Web and Mobile Engineering\nOption: Web Intelligence",
        },
        {
          id: "second-p-about",
          school: "Preparatory Classes",
          degree: "Mathematics and Physics",
          year: "2013 – 2015",
          details: "Option: Mathematics and Physics",
        },
        {
          id: "third-p-about",
          school: "High School Diploma",
          degree: "Mathematics",
          year: "2013",
          details: "Option: Mathematics",
        },
      ],
      certifications: [
        {
          id: "cert-1",
          name: "AWS Developer Associate",
          issuer: "Amazon Web Services",
          year: "2025"
        },
        {
          id: "cert-2",
          name: "AWS Cloud Practitioner",
          issuer: "Amazon Web Services",
          year: "2023"
        },
        {
          id: "cert-3",
          name: "Agile Project Management",
          issuer: "Google (Coursera)",
          year: "2023"
        },
        {
          id: "cert-4",
          name: "Google Cloud Fundamentals: Core Infrastructure",
          issuer: "Google Cloud (Coursera)",
          year: "2023"
        },
        {
          id: "cert-5",
          name: "Front-End Web Development with React",
          issuer: "Coursera",
          year: "2021"
        }
      ]
    };
  }

  componentDidMount() {
    // Trigger the animation after the component is mounted
    this.setState({ fadeIn: true });
  }

  render() {
    return (
      <DarkBackground>
        <section id="about" className={`about-mf sect-pt4 route ${this.state.fadeIn ? 'fade-in' : ''}`}>
          <AboutSeo/>
          <div className="container about-me-container">
            <div className="row">
              <div className="col-sm-12">
                <div className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="container">
                        <div className="title-box-2">
                          <h5 style={{color:"orange"}}  className="title-left">About me</h5>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">Software Engineer & Architect</h5>
                            <p className="card-text">
                              Proficient in Software development with expertise in Java/Spring, React.js and T24(Temenos). 
                              Skilled in designing scalable architectures and ensuring code quality through 
                              robust quality assurance practices.
                            </p>
                          </div>
                        </div>

                        <div className="card mt-3">
                          <div className="card-body">
                            <h5 className="card-title">Industry Experience</h5>
                            <p className="card-text">
                              Successfully executed complex projects in aerospace, finance, and banking sectors. 
                              Specialized in delivering high-quality solutions while maintaining reliability 
                              and performance standards.
                            </p>
                          </div>
                        </div>

                        <div className="card mt-3">
                          <div className="card-body">
                            <h5 className="card-title">Continuous Growth</h5>
                            <p className="card-text">
                              Actively engaged in continuous learning to stay abreast of the latest technologies 
                              and methodologies. Committed to implementing best practices and emerging patterns 
                              in software development.
                            </p>
                          </div>
                        </div>
                        <div className="card mt-3">
                          <div className="card-body">
                            <h5 className="card-title">Technical Lead</h5>
                            <p className="card-text">
                              Experienced in leading technical teams, mentoring developers, and driving architectural 
                              decisions. Strong focus on fostering innovation and maintaining technical excellence 
                              across projects.
                            </p>
                          </div>
                           </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="container">
                        <div className="title-box-2">
                          <h5 style={{color:"orange"}} className="title-left">Education</h5>
                        </div>
                        
                    

                        <ul className="list-group">
                          {this.state.education.map((edu) => (
                            <li key={edu.id} className="list-group-item">
                              <h4>{edu.school}</h4>
                              <p>{edu.degree}</p>
                              <p>{edu.year}</p>
                              {edu.details && <p>{edu.details}</p>}
                            </li>
                          ))}
                        </ul>

                        <div className="card mt-4">
                          <div className="card-body">
                            <h5 className="card-title">Professional Certifications</h5>
                            <ul className="list-unstyled mb-0">
                              {this.state.certifications.map((cert) => (
                                <li key={cert.id} className="mb-3">
                                  <strong>{cert.name}</strong>
                                  <br />
                                  <small>
                                    {cert.issuer} - {cert.year}
                                    {cert.status && ` (${cert.status})`}
                                  </small>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PersonSchema/>
          <BackHome />
        </section>
      </DarkBackground>
    );
  }
}

export default About;
