import React, { useEffect, useState, useRef } from "react";
import "./stars.scss";
import '../css/intro.css';
import { ReactTyped } from 'react-typed';
import PersonSchema from "../seo/PersonSchema";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Footer from './Footer';

const Intro = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('skills');
  const [showFooter, setShowFooter] = useState(false);
  const footerRef = useRef(null);

  useEffect(() => {
    // Set body background color immediately
    document.body.style.backgroundColor = '#000';
    
    // Small delay before showing content
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => {
      clearTimeout(timer);
      // Reset body background when component unmounts
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSection(prev => {
        if (prev === 'skills') return 'expertise';
        if (prev === 'expertise') return 'languages';
        return 'skills';
      });
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      
      // Show footer only when scrolled to very bottom (within 10px)
      const isAtBottom = (windowHeight + scrollTop) >= (documentHeight - 10);
      setShowFooter(isAtBottom);
    };

    // Initial check
    handleScroll();
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const expertise = [
    "Core Banking Solutions",
    "Digital Transformation",
    "API Development",
    "Cloud Architecture"
  ];

  const handleNavigation = (path) => {
    // Close the navbar by simulating a click on the navbar toggler if it exists
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');
    
    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click();
    }
    
    // Navigate to the desired path
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Abdelhamid ZEGLAMI | Senior Software Engineer & Banking Solutions Expert</title>
        <meta 
          name="description" 
          content="Senior Software Engineer specializing in Temenos T24, Core Banking Solutions, and Full Stack Development. Expert consultant available in France and Luxembourg."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Person",
              "name": "Abdelhamid ZEGLAMI",
              "jobTitle": "Senior Software Engineer",
              "knowsLanguage": ["French", "Arabic", "English"],
              "description": "Banking Software Expert & Solutions Architect",
              "url": "https://zegit.me",
              "sameAs": [
                "https://www.linkedin.com/in/your-linkedin",
                "https://github.com/your-github"
              ],
              "workLocation": {
                "@type": "Place",
                "address": {
                  "@type": "PostalAddress",
                  "addressCountry": ["France", "Luxembourg"]
                }
              }
            }
          `}
        </script>
      </Helmet>

      <div id="home" className={`intro route ${isVisible ? 'fade-in' : ''}`}>
        <div id="intro-stars"></div>
        <div id="intro-stars2"></div>
        <div id="intro-stars3"></div>

        <div className="gradient-overlay"></div>

        <div className="floating-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>

        <div className="intro-content" style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}>
          <div className="table-cell" style={{ flex: '1' }}>
            <div className="container">
              <div className={`intro-body ${isVisible ? 'slide-in-up' : ''}`}>
                <div style={{ paddingBottom: '80px' }}>
                  <div className="intro-subtitle" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={`expertise-container ${activeSection === 'expertise' ? 'active' : ''}`}>
                      <ReactTyped
                        strings={expertise}
                        typeSpeed={70}
                        backDelay={1100}
                        backSpeed={30}
                        loop
                        showCursor={true}
                        cursorChar="|"
                        className="expertise-text"
                      />
                    </div>
                  </div>

                  <div className="intro-text-card" style={{ margin: '5px auto', maxWidth: '800px' }}>
                    <div className="card-content">
                      <p className="intro-text cinematic-fade" style={{ padding: '10px', textAlign: 'center' }}>
                        <span className="highlight-text">Hi, I'm a Solution Architect</span>
                        {' '}with expertise in{' '}
                        <span className="specialty-text">Web development, Core Banking T24 
                        and Enterprise Architecture</span>. With over{' '}
                        6 years of experience, 
                        I specialize in <span className="tech-highlight">Temenos T24</span>,{' '}
                        <span className="tech-highlight">digital transformation</span>, 
                        and <span className="tech-highlight">designing scalable systems</span>.
                      </p> 
                    </div>
                  </div>

                  <div className="action-cards" style={{ 
                    margin: '5px auto',
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '10px'
                  }}>
                    <div className="action-card portfolio" onClick={() => handleNavigation('/portfolio')}>
                      <i className="bi bi-briefcase card-icon"></i>
                      <span>Projects</span>
                    </div>
                    <div className="action-card blog" onClick={() => handleNavigation('/blog')}>
                      <i className="bi bi-book card-icon"></i>
                      <span>Blog</span>
                    </div>
                    <div className="action-card contact" onClick={() => handleNavigation('/contact')}>
                      <i className="bi bi-envelope card-icon"></i>
                      <span>Contact me</span>
                    </div>
                    <div className="action-card contact" onClick={() => navigate('/about')}>
                      <i className="bi bi-envelope card-icon"></i>
                      <span>About me</span>
                    </div>
                  </div>

                  <div className="social-links" style={{ 
                    margin: '5px auto',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '15px'
                  }}>
                    <a href="https://github.com/zeglami" target="_blank" rel="noopener noreferrer" className="social-link">
                      <span className="ico-circle">
                        <i className="ion-social-github"></i>
                      </span>
                    </a>
                    <a href="https://www.linkedin.com/in/zeglami" target="_blank" rel="noopener noreferrer" className="social-link">
                      <span className="ico-circle">
                        <i className="ion-social-linkedin"></i>
                      </span>
                    </a>
                    <a href="https://www.youtube.com/channel/UCKR0DAeuw6ja8UrMF_ptdOA" target="_blank" rel="noopener noreferrer" className="social-link">
                      <span className="ico-circle">
                        <i className="ion-social-youtube"></i>
                      </span>
                    </a>
                  </div>

                  <div className="status-badge" style={{ 
                    margin: '5px auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <span className="pulse"></span>
                    <span>Available for Projects</span>
                  </div>
                </div>

              
              </div>
            </div>
          </div>
        </div>

        <PersonSchema />
      </div>
    </>
  );
};

export default Intro;
