import React from "react";
import { Helmet } from "react-helmet";

const BlogSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Technical Blog | Banking Software & Temenos T24 Insights</title>
        <meta
          name="description"
          content="Technical articles about Temenos T24, Banking Software Development, Java Spring Boot, and Software Engineering best practices by Abdelhamid ZEGLAMI."
        />
        <link rel="canonical" href="https://zegit.me/blog" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Blog",
              "name": "Abdelhamid ZEGLAMI's Tech Blog",
              "description": "Technical insights about Banking Software, Temenos T24, and Software Engineering",
              "url": "https://zegit.me/blog",
              "author": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer"
              },
              "publisher": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI"
              },
              "keywords": [
                "Temenos T24",
                "Banking Software",
                "Java Spring Boot",
                "Software Engineering",
                "Technical Blog"
              ]
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default BlogSeo; 