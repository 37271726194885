import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faUniversity, faSchool, faMobile } from "@fortawesome/free-solid-svg-icons";
import DarkBackground from './DarkBackground';
import "../css/darkTheme.css";
import "./../css/Exp.css";
import PersonSchema from "../seo/PersonSchema";

class Experience extends React.Component {
  constructor() {
    super();
    this.state = {
      // Your state data remains unchanged
    };
  }

  render() {
    return (
      <DarkBackground>
        <section id="experience" className="about-mf sect-pt4 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h5 style={{color:"orange"}} className="title-left">Experience</h5>
                <div className="box-shadow-full">
                  <VerticalTimeline
                    animate={true}
                    layout="1-column-left"
                    lineColor={"#f27500"}
                  >
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      icon={
                        <FontAwesomeIcon
                          icon={faPlane}
                          style={{ color: "#f27500" }}
                        />
                      }
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(33, 150, 243)",
                      }}
                      date="February 2022 - Now"
                    >
                      <h3 className="vertical-timeline-element-title">
                        Research & Development Software Engineer
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        Airbus
                      </h4>
                      <div className="container mt-4">
                        <h2>Key points </h2>
                        <ul>
                          <li>
                            PLM Improvement/Impact Evaluation of Changes
                          </li>
                          <li>SAFe Framework, Scrum, Jira, and Confluence Management</li>
                          <li>
                            Developing Java (JPO) for Document Control Check
                          </li>
                          <li>
                            Developing FMEA, DEFMEA, PFMEA components
                          </li>
                          <li>
                            Performance Optimization
                          </li>
                          <li>
                            Testing and Continuous Integration
                          </li>
                          <li>
                            Source Code Quality Control
                          </li>
                        </ul>
                        <h2>Tools</h2>
                        <p>Java 11 / 17,JPO,Testability,Javascript MQL, Mobaxterm, SonarQube, CSV, JSON, GitLab, DevOps, SonarQube, Junit, Mockito, Gradle, 3dexperience, EKL,VS Code ,intellij idea,Jira,Confluence,Selenuim,TestNG,extent report</p>
                      </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      icon={
                        <FontAwesomeIcon
                          icon={faUniversity}
                          style={{ color: "#f27500" }}
                        />
                      }
                      date="September 2019 - January 2022"
                    >
                      <h3 className="vertical-timeline-element-title">
                        Research & Development Software Engineer
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        BCP Technologies
                      </h4>
                      <div className="container mt-4">
                        <h2>Key Points</h2>
                        <ul>
                          <li>
                            Writing and updating Functional Requirements
                            Specifications.
                          </li>
                          <li>
                            Backend Development:microservices, restful web services
                          </li>
                          <li>Frontend Development: Created interfaces using React.js, managed states with Redux</li>
                          <li>
                            Testing and CI/CD: Utilized GitLab for DevOps deployment, managed CI/CD pipelines on Docker, and ensured high code quality with SonarQube.
                          </li>
                          <li>
                            Performance Testing: Conducted performance testing with Apache JMeter, ensuring the platform's robustness
                          </li>
                          <li>
                            Scrum Methodology: Led Scrum methodology rituals and managed sprints effectively.
                          </li>
                        </ul>

                        <h2>Tools</h2>
                        <p>ReactJS, NodeJS, Spring boot, Temenos T24.</p>
                      </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      date="1 February 2019 - 28 June 2019"
                      icon={
                        <FontAwesomeIcon
                          icon={faUniversity}
                          style={{ color: "#f27500" }}
                        />
                      }
                    >
                      <h3 className="vertical-timeline-element-title">
                        Full stack developer
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        Banque Centrale Populaire (BCP)
                      </h4>
                      <div className="container mt-4">
                        <h2>Key points </h2>
                        <ul>
                          <li>
                            Digitalization Leadership
                          </li>
                          <li>Client Needs Analysis</li>
                          <li>
                            Functional and technical specifications
                          </li>
                          <li>
                            Frontend Development with React.js:
                          </li>
                          <li>
                            Responsive Interface Design
                          </li>
                          <li>
                            Backend Development with Spring Boot
                          </li>
                          <li>
                            Unit Testing with TestNG
                          </li>
                        </ul>
                        <h2>Tools</h2>
                        <p>	Java 8, React.js, PowerDesigner, Spring boot,REST API, Hebernate, Oracle 11g, Reactstrap, GitHub, Tomcat, JUnit.
                        </p>
                      </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      date="1 July 2018 - 31 August 2018"
                      icon={
                        <FontAwesomeIcon
                          icon={faMobile}
                          style={{ color: "#f27500" }}
                        />
                      }
                    >
                      <h3 className="vertical-timeline-element-title">
                        Full stack developer
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        INTELLCAP
                      </h4>
                      <div className="container mt-4">
                        <h2>Key points </h2>
                        <ul>
                          <li>Mobile app(ios and android) for an Environmental Education Center</li>
                          <li>
                            Mobile App Development: React Native, Expo XDE</li>
                          <li>Backend Development: Symfony</li>
                          <li>Database Management: SQL Server</li>
                        </ul>
                      </div>
                      <h2>Tools</h2>
                      <p>
                        Tools: Expo XDE, React Native, Symfony, SQL Server. Mobile
                        application (on Android & iOS) for an Environmental
                        Education Center (EEC).
                      </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      date="10 July 2016 - 31 August 2016"
                      icon={
                        <FontAwesomeIcon
                          icon={faSchool}
                          style={{ color: "#f27500" }}
                        />
                      }
                    >
                      <h3 className="vertical-timeline-element-title">
                        Full stack developer
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        Phoenix IT Company
                      </h4>
                      <div className="container mt-4">
                        <h2>Key points </h2>
                        <ul>
                          <li>
                            Platform learning for employees</li>
                          <li>Instructor Space/Employee Space/Admin Space</li>
                        </ul>
                      </div>
                      <h2>Tools</h2>
                      <p>
                        Tools: ReactJS, Nodejs, express js, MySQL. E-learning
                        platform for employees.
                      </p>
                    </VerticalTimelineElement>
                  </VerticalTimeline>
                </div>
              </div>
            </div>
          </div>
          <PersonSchema/>
        </section>
      </DarkBackground>
    );
  }
}

export default Experience;
