import React from 'react';
import './stars.scss';

const DarkBackground = ({ children }) => {
  return (
    <div className="dark-theme-page">
      <div className="gradient-overlay"></div>
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      {children}
    </div>
  );
};

export default DarkBackground; 