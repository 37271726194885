import React from "react";
import { Helmet } from "react-helmet";

const ExperienceSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Professional Experience | Temenos T24 Expert - Abdelhamid ZEGLAMI</title>
        <meta
          name="description"
          content="Over 5 years of experience in banking software development, Temenos T24 implementation, and full-stack development. Projects with major banks in France and Luxembourg."
        />
        <link rel="canonical" href="https://zegit.me/experience" />
        
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfilePage",
              "mainEntity": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer",
                "worksFor": [
                  {
                    "@type": "Organization",
                    "name": "AIRBUS",
                    "description": "Aerospace software development"
                  },
                  {
                    "@type": "Organization",
                    "name": "BCP Technologies",
                    "description": "Banking software solutions"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default ExperienceSeo; 