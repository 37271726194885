import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/back-home.css';

const BackHome = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === '/') {
    return null;
  }

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div 
      className="back-home" 
      onClick={handleClick}
      title="Back to Home"
    >
      <div className="home-icon">
        <div className="roof"></div>
        <div className="base"></div>
      </div>
    </div>
  );
};

export default BackHome; 