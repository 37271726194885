import React from "react";
import { Helmet } from "react-helmet";

const BankingSolutionsSeo = () => {
  return (
    <div className="application">
      <Helmet>
        <title>Banking Solutions | Temenos T24 Implementation Expert</title>
        <meta
          name="description"
          content="Specialized banking solutions including Temenos T24/Transact implementation, core banking development, and digital transformation services. Expert consultant available in France and Luxembourg."
        />
        <link rel="canonical" href="https://zegit.me/banking-solutions" />
        
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Service",
              "serviceType": "Banking Software Solutions",
              "provider": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer"
              },
              "areaServed": [
                {
                  "@type": "Country",
                  "name": "France"
                },
                {
                  "@type": "Country",
                  "name": "Luxembourg"
                }
              ],
              "hasOfferCatalog": {
                "@type": "OfferCatalog",
                "name": "Banking Software Services",
                "itemListElement": [
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Temenos T24 Implementation",
                      "description": "Expert implementation and customization of Temenos T24/Transact core banking system"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Core Banking Development",
                      "description": "Custom banking software development and integration services"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default BankingSolutionsSeo; 