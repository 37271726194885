import React from "react";
import { Helmet } from "react-helmet";

const BlogArticleSeo = ({ article }) => {
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const description = article ? stripHtml(article.description).substring(0, 160) : '';

  return (
    <div className="application">
      <Helmet>
        <title>{article?.title} | Abdelhamid ZEGLAMI's Tech Blog</title>
        <meta name="description" content={description} />
        <meta name="author" content={article?.author} />
        <link rel="canonical" href={`https://zegit.me/blog/${article?.guid}`} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article?.title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://zegit.me/blog/${article?.guid}`} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article?.title} />
        <meta name="twitter:description" content={description} />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": "${article?.title}",
              "description": "${description}",
              "author": {
                "@type": "Person",
                "name": "${article?.author}"
              },
              "datePublished": "${article?.pubDate}",
              "publisher": {
                "@type": "Person",
                "name": "Abdelhamid ZEGLAMI",
                "jobTitle": "Senior Software Engineer",
                "url": "https://zegit.me"
              },
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://zegit.me/blog/${article?.guid}"
              },
              "keywords": [
                "Temenos T24",
                "Banking Software",
                "Java Spring Boot",
                "Software Engineering",
                "Technical Blog"
              ]
            }
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default BlogArticleSeo; 