// PersonSchema.js
//
import React from 'react';

const WebsiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Abdelhamid ZEGLAMI - Software Engineering Expert",
  "url": "https://zegit.me",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://zegit.me/search?q={search_term_string}",
    "query-input": "required name=search_term_string"
  }
};

const SiteNavigationSchema = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  "itemListElement": [
    {
      "@type": "SiteNavigationElement",
      "position": 1,
      "name": "About",
      "description": "Professional Background & Expertise",
      "url": "https://zegit.me/about"
    },
    {
      "@type": "SiteNavigationElement",
      "position": 2,
      "name": "Portfolio",
      "description": "Past Projects & Case Studies",
      "url": "https://zegit.me/portfolio"
    },
    {
      "@type": "SiteNavigationElement",
      "position": 3,
      "name": "Blog",
      "description": "Technical Articles & Insights",
      "url": "https://zegit.me/blog"
    },
    {
      "@type": "SiteNavigationElement",
      "position": 4,
      "name": "Contact",
      "description": "Get in Touch",
      "url": "https://zegit.me/contact"
    }
  ]
};

const PersonSchema = () => {
  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(WebsiteSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(SiteNavigationSchema)}
      </script>
      <script type="application/ld+json">
        {`
    {
      "@context": "http://schema.org",
      "@type": "Person",
      "name": "Abdelhamid ZEGLAMI",
      "alternateName": "ZEGLAMI Abdelhamid",
      "jobTitle": "Senior Software Engineer",
      "description": "Senior Software Engineer offering freelance services in France, Luxembourg, and remote worldwide. Specialized in Temenos T24/Transact implementation, Java Spring Boot development, and banking solutions architecture.",
      "url": "https://zegit.me",
      "sameAs": [
        "https://linkedin.com/zeglami",
        "https://github.com/zeglami"
      ],
      "alumniOf": {
        "@type": "EducationalOrganization",
        "name": "Ecole Nationale Supérieure de l’Informatique et d’Analyse des Systèmes"
      },
      "memberOf": {
        "@type": "Organization",
        "name": "BCP Securities"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "Banque Populaire"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "BCP Technologies"
      },
      "worksFor": {
        "@type": "Organization",
        "name": "AIRBUS"
      },
      "hasOccupation": [
        {
          "@type": "JobPosting",
          "title": "Ingénieur d'étude et développement",
          "startDate": "2022-02-01",
          "endDate": "2023-10-31",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "AIRBUS"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Paris",
              "addressRegion": "ile de France",
              "addressCountry": "France"
            }
          }
        },
        {
          "@type": "JobPosting",
          "title": "FONCTION",
          "startDate": "2019-09-01",
          "endDate": "2022-01-31",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "BCP Securities/BCP Technologies"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Casaclanca",
              "addressRegion": "Grand Casablanca",
              "addressCountry": "Morocco"
            }
          }
        },
        {
          "@type": "JobPosting",
          "title": "Développeur full stack",
          "startDate": "2019-02-01",
          "endDate": "2019-06-30",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "Banque Centrale Populaire(BCP)/ Banque Centrale Populaire(BCP)"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Toulouse",
              "addressRegion": "France",
              "addressCountry": "France"
            }
          }
        }
      ],
      "hasSkill": [
        "Temenos T24",
        "Temenos Transact",
        "Core Banking Solutions",
        "Java Spring Boot",
        "React.js",
        "Banking Software Development",
        "Software Architecture",
        "Java",
        "Spring Boot",
        "JavaScript",
        "HTML",
        "CSS",
        "SQL",
        "Jira",
        "Confluence",
        "GitLab",
        "Maven",
        "Docker",
        "AWS",
        "Agile",
        "Scrum",
        "Safe Frawork",
        "UML",
        "Testing (JUnit, TestNG, Selenium)"
      ],
      "knowsAbout": [
        "Core Banking Systems",
        "Temenos T24 Customization",
        "Banking Software Implementation",
        "Full Stack Development",
        "Enterprise Architecture"
      ],
      "availableLocation": [
        {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "France"
          }
        },
        {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "Luxembourg"
          }
        }
      ],
      "workLocation": {
        "@type": "Place",
        "description": "Available for on-site work in France and Luxembourg, and remote work worldwide"
      },
      "makesOffer": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Temenos T24/Transact Implementation",
            "description": "Expert consulting for core banking implementation and customization"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Banking Software Development",
            "description": "Full stack development services for banking and financial institutions"
          }
        }
      ]
    }
  `}
      </script>
    </>
  );
};

export default PersonSchema;
